var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main", [
    _c(
      "div",
      { staticClass: "hero-image bg-no-repeat bg-center bg-cover h-screen-66" },
      [
        _c(
          "div",
          {
            staticClass: "w-5/6 md:w-3/4 lg:w-1/2 px-2 pt-6 mx-auto text-white"
          },
          [
            _c("h1", { staticClass: "block text-center" }, [
              _vm._v("Welcome to Portland in the year 2035")
            ]),
            _vm._v(" "),
            _c("section", { staticClass: "flex flex-col md:flex-row" }, [
              _c(
                "div",
                { staticClass: "w-full md:w-1/2 p-2" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "block w-full px-2 py-3 bg-orange-500 border border-orange-900 rounded text-center text-orange-900 hover:bg-orange-600 focus:outline-none focus:shadow-outline focus:bg-orange-600",
                      attrs: { to: "/streets" }
                    },
                    [_vm._v("Browse your streets")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "w-full md:w-1/2 p-2" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "block w-full px-2 py-3 bg-gray-200 border border-gray-900 rounded text-center text-gray-900 hover:bg-gray-400 focus:outline-none focus:shadow-outline focus:bg-gray-400",
                      attrs: { to: "/text" }
                    },
                    [_vm._v("Read about our plans")]
                  )
                ],
                1
              )
            ])
          ]
        )
      ]
    ),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _vm._m(1)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "container px-2 py-3 mx-auto" }, [
      _c("h2", [_vm._v("Portland's Transportation System Plan")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n      Portland’s 2035 Transportation System Plan (TSP) is a roadmap for the next 20 years of transportation policy and\n      investment, helping us face the challenges of a growing city. The TSP outlines how we balance safety, equity and\n      the demands of our economy. It enshrines the values of complete neighborhoods with more options for getting\n      around. And it tells the story of how all of our planning efforts lead us to a less congested, more livable\n      tomorrow.\n    "
        )
      ]),
      _vm._v(" "),
      _c("h3", [_vm._v("What is the TSP?")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n      The Transportation System Plan is the 20-year plan to guide transportation policies and investments in Portland\n      by:\n    "
        )
      ]),
      _vm._v(" "),
      _c("ul", [
        _c("li", [
          _vm._v(
            "\n        supporting the City’s commitment to Vision Zero by saving lives and reducing injuries to all people using our\n        transportation system\n      "
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v("helping transit and freight vehicles to move more reliably")
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v("reducing, carbon emissions and promoting healthy lifestyles")
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "keeping more money in the local economy, as we spend less on vehicles and fuel"
          )
        ]),
        _vm._v(" "),
        _c("li", [_c("span", [_vm._v("creating great places")])])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "container px-2 py-3 mx-auto" }, [
      _c("h3", [_vm._v("Using the TSP Digital Document")]),
      _vm._v(" "),
      _c("p", [
        _c("span", [
          _vm._v(
            "\n        This digital version of the document is intended to serve as a tool and resource for people trying to use\n        different components of the TSP. Sections available here include Major Projects, Street Classifications,\n        Master Street Plans, and Area Plans. This is by no means a full version of the document, so visit this site to\n        view the document in its entirety:\n      "
          )
        ]),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "border-b-2 border-black",
            attrs: {
              href:
                "https://www.portland.gov/transportation/planning/tsp-document-downloads"
            }
          },
          [
            _vm._v(
              "https://www.portland.gov/transportation/planning/tsp-document-downloads"
            )
          ]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }