var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      staticClass:
        "p-4 w-full border border-l-8 flex items-center justify-between",
      class: _vm.colors
    },
    [
      _vm._v("\n  " + _vm._s(_vm.item.text) + "\n  "),
      _vm.item.dismissible
        ? _c(
            "button",
            {
              on: {
                click: function($event) {
                  return _vm.removeMessage(_vm.item.id)
                }
              }
            },
            [
              _c("i", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.feather.icons["x"].toSvg({ class: "w-5 h-5" })
                  )
                }
              })
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }