var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "list-none" },
    [
      _c(
        "transition-group",
        { attrs: { name: "pop", mode: "out-in" } },
        _vm._l(_vm.messages, function(message) {
          return _c("message-item", {
            key: message.id,
            staticClass: "my-2",
            attrs: { item: message }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }