








import Vue from 'vue';
import { mapState } from 'vuex';
import MessageItem from '@/components/message/Full.vue';
export default Vue.extend({
  name: 'MessageList',
  components: {
    MessageItem
  },
  computed: {
    ...mapState(['messages'])
  }
});
