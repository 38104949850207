var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "fill-current",
      staticStyle: { "enable-background": "new 0 0 243.2 90" },
      attrs: {
        version: "1.1",
        id: "Primary_w_x2F__Text_0_0_0_85_copy",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        x: "0px",
        y: "0px",
        viewBox: "0 0 243.2 90",
        "xml:space": "preserve"
      }
    },
    [
      _c("g", [
        _c("g", [
          _c("g", [
            _c("polygon", {
              attrs: {
                points:
                  "189.4,3.8 189.4,18.6 205.7,18.6 205.7,70.6 223.8,70.6 223.8,18.6 240,18.6 240,3.8 \t\t\t"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M99.8,35.6v-0.5c3.1-0.8,5.7-2.5,7.6-5.1c1.9-2.7,2.9-5.8,2.9-9.6c0-5.7-2.2-9.9-6.6-12.6\n\t\t\t\tc-4.4-2.7-11.1-4-20.2-4H60.2v66.8h26c7.9,0,14.1-1.7,18.6-5.2c4.6-3.5,6.8-8.3,6.8-14.3c0-4.1-0.9-7.4-2.8-10\n\t\t\t\tC107,38.5,104,36.7,99.8,35.6z M78.2,17.5h5.1c5.7,0,8.5,1.9,8.5,5.6c0,2.1-0.7,3.7-2.1,4.8c-1.4,1.1-3.4,1.6-6,1.6h-5.4V17.5z\n\t\t\t\t M84.5,56.7h-6.4v-14h6c2.9,0,5,0.6,6.6,1.8c1.5,1.2,2.3,2.9,2.3,5.1C93,54.3,90.2,56.7,84.5,56.7z"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M170.7,9c-0.9-0.6-1.8-1.2-2.8-1.7c-2.4-1.3-5-2.3-7.7-3c-2.7-0.7-5.6-1.1-8.5-1.1c-8.8,0-16.8,3.4-22.9,8.8\n                c-0.4,0.4-0.8,0.7-1.2,1.1c-6.2,6.1-10,14.7-10,24.1c0,9.4,3.8,17.9,10,24.1c0.4,0.4,0.8,0.8,1.2,1.1c6,5.5,14.1,8.8,22.9,8.8\n                c2.9,0,5.8-0.4,8.5-1.1c2.7-0.7,5.3-1.7,7.7-3c1-0.5,1.9-1.1,2.8-1.7c9-6.1,15-16.5,15-28.2S179.8,15.1,170.7,9z M151.7,56.2\n                c-10.5,0-19-8.5-19-19s8.5-19,19-19c10.5,0,19,8.5,19,19S162.2,56.2,151.7,56.2z"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M45.3,9.4c-4.2-3.7-10.6-5.5-19-5.5H3.2v66.8h18V48.5h5.1c8.1,0,14.4-2,18.8-6.1c4.4-4.1,6.6-9.8,6.6-17.3\n\t\t\t\tC51.7,18.3,49.6,13,45.3,9.4z M31,31.5c-1.6,1.5-3.8,2.3-6.5,2.3h-3.3V18.4h4.7c5,0,7.5,2.3,7.5,6.8C33.4,27.8,32.6,29.9,31,31.5\n\t\t\t\tz"
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c("g", [
          _c("path", {
            attrs: {
              d:
                "M9,81.2c0,0.9-0.3,1.6-0.8,2.1C7.7,83.8,7,84,6,84H5.4v2.7H3.2v-8H6c1,0,1.8,0.2,2.3,0.7\n\t\t\tC8.8,79.8,9,80.4,9,81.2z M5.4,82.3h0.4c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.4,0.3-0.8c0-0.5-0.3-0.8-0.9-0.8H5.4V82.3z"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M17.8,82.7c0,1.4-0.3,2.4-1,3.1c-0.7,0.7-1.6,1.1-2.9,1.1c-1.3,0-2.2-0.4-2.9-1.1c-0.7-0.7-1-1.7-1-3.1\n\t\t\tc0-1.3,0.3-2.4,1-3.1c0.7-0.7,1.6-1.1,2.9-1.1c1.3,0,2.2,0.3,2.9,1S17.8,81.3,17.8,82.7z M12.3,82.7c0,1.6,0.5,2.3,1.6,2.3\n\t\t\tc0.5,0,1-0.2,1.2-0.6c0.3-0.4,0.4-1,0.4-1.8c0-0.8-0.1-1.4-0.4-1.8c-0.3-0.4-0.7-0.6-1.2-0.6C12.8,80.3,12.3,81.1,12.3,82.7z"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M21.4,83.8v2.9h-2.2v-8h2.6c2.2,0,3.3,0.8,3.3,2.4c0,0.9-0.5,1.6-1.4,2.2l2.3,3.5h-2.5L22,83.8H21.4z\n\t\t\t M21.4,82.1h0.4c0.8,0,1.1-0.3,1.1-1c0-0.6-0.4-0.8-1.1-0.8h-0.4V82.1z"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: { d: "M30.4,86.7h-2.2v-6.3h-2v-1.8h6.1v1.8h-2V86.7z" }
          }),
          _vm._v(" "),
          _c("path", { attrs: { d: "M33.5,86.7v-8h2.2v6.3h3.1v1.8H33.5z" } }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M45,86.7l-0.4-1.5H42l-0.4,1.5h-2.4l2.6-8.1h2.9l2.7,8.1H45z M44.1,83.4l-0.3-1.3c-0.1-0.3-0.2-0.7-0.3-1.1\n\t\t\tc-0.1-0.5-0.2-0.8-0.2-1c0,0.2-0.1,0.5-0.2,0.9c-0.1,0.4-0.3,1.3-0.6,2.5H44.1z"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M55.9,86.7H53L50.1,81h0c0.1,0.9,0.1,1.6,0.1,2v3.6h-1.9v-8h2.8l2.9,5.6h0c-0.1-0.8-0.1-1.5-0.1-1.9v-3.6h1.9\n\t\t\tV86.7z"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M64.4,82.5c0,1.3-0.4,2.4-1.1,3.1c-0.7,0.7-1.8,1.1-3.1,1.1h-2.6v-8h2.8c1.3,0,2.3,0.3,3,1\n\t\t\tC64.1,80.3,64.4,81.3,64.4,82.5z M62.2,82.6c0-0.7-0.1-1.3-0.4-1.6c-0.3-0.4-0.7-0.5-1.3-0.5h-0.6v4.5h0.5c0.7,0,1.1-0.2,1.4-0.6\n\t\t\tC62,84,62.2,83.4,62.2,82.6z"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M68.8,78.7h2.8c1.1,0,1.9,0.2,2.4,0.5c0.5,0.3,0.8,0.8,0.8,1.5c0,0.5-0.1,0.8-0.3,1.2s-0.5,0.5-0.9,0.6v0.1\n\t\t\tc0.5,0.1,0.9,0.4,1.1,0.7c0.2,0.3,0.3,0.7,0.3,1.2c0,0.7-0.3,1.3-0.8,1.7c-0.5,0.4-1.3,0.6-2.2,0.6h-3.1V78.7z M70.9,81.7h0.7\n\t\t\tc0.3,0,0.6-0.1,0.7-0.2c0.2-0.1,0.3-0.3,0.3-0.6c0-0.5-0.3-0.7-1-0.7h-0.6V81.7z M70.9,83.3V85h0.8c0.7,0,1-0.3,1-0.9\n\t\t\tc0-0.3-0.1-0.5-0.3-0.6c-0.2-0.1-0.4-0.2-0.8-0.2H70.9z"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M83.2,78.7v4.8c0,1-0.3,1.9-0.9,2.4c-0.6,0.6-1.5,0.9-2.6,0.9c-1.1,0-1.9-0.3-2.5-0.8\n\t\t\tc-0.6-0.6-0.9-1.4-0.9-2.4v-4.9h2.2v4.7c0,0.6,0.1,1,0.3,1.2c0.2,0.3,0.5,0.4,0.9,0.4c0.4,0,0.8-0.1,1-0.4\n\t\t\tc0.2-0.3,0.3-0.7,0.3-1.2v-4.7H83.2z"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M87,83.8v2.9h-2.2v-8h2.6c2.2,0,3.3,0.8,3.3,2.4c0,0.9-0.5,1.6-1.4,2.2l2.3,3.5h-2.5l-1.7-2.9H87z M87,82.1\n\t\t\th0.4c0.8,0,1.1-0.3,1.1-1c0-0.6-0.4-0.8-1.1-0.8H87V82.1z"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M97.2,86.7h-4.8v-8h4.8v1.7h-2.6v1.3H97v1.7h-2.4v1.5h2.6V86.7z"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M103.5,86.7l-0.4-1.5h-2.6l-0.4,1.5h-2.4l2.6-8.1h2.9l2.7,8.1H103.5z M102.7,83.4l-0.3-1.3\n\t\t\tc-0.1-0.3-0.2-0.7-0.3-1.1c-0.1-0.5-0.2-0.8-0.2-1c0,0.2-0.1,0.5-0.2,0.9c-0.1,0.4-0.3,1.3-0.6,2.5H102.7z"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M113.6,78.7v4.8c0,1-0.3,1.9-0.9,2.4c-0.6,0.6-1.5,0.9-2.6,0.9c-1.1,0-1.9-0.3-2.5-0.8\n\t\t\tc-0.6-0.6-0.9-1.4-0.9-2.4v-4.9h2.2v4.7c0,0.6,0.1,1,0.3,1.2c0.2,0.3,0.5,0.4,0.9,0.4c0.4,0,0.8-0.1,1-0.4\n\t\t\tc0.2-0.3,0.3-0.7,0.3-1.2v-4.7H113.6z"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M125.7,82.7c0,1.4-0.3,2.4-1,3.1c-0.7,0.7-1.6,1.1-2.9,1.1c-1.3,0-2.2-0.4-2.9-1.1c-0.7-0.7-1-1.7-1-3.1\n\t\t\tc0-1.3,0.3-2.4,1-3.1c0.7-0.7,1.6-1.1,2.9-1.1c1.3,0,2.2,0.3,2.9,1S125.7,81.3,125.7,82.7z M120.2,82.7c0,1.6,0.5,2.3,1.6,2.3\n\t\t\tc0.5,0,1-0.2,1.2-0.6c0.3-0.4,0.4-1,0.4-1.8c0-0.8-0.1-1.4-0.4-1.8c-0.3-0.4-0.7-0.6-1.2-0.6C120.7,80.3,120.2,81.1,120.2,82.7z"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M129.3,86.7h-2.1v-8h4.7v1.7h-2.6v1.5h2.4v1.7h-2.4V86.7z"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: { d: "M139.7,86.7h-2.2v-6.3h-2v-1.8h6.1v1.8h-2V86.7z" }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M145,83.8v2.9h-2.2v-8h2.6c2.2,0,3.3,0.8,3.3,2.4c0,0.9-0.5,1.6-1.4,2.2l2.3,3.5h-2.5l-1.7-2.9H145z\n\t\t\t M145,82.1h0.4c0.8,0,1.1-0.3,1.1-1c0-0.6-0.4-0.8-1.1-0.8H145V82.1z"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M155.3,86.7l-0.4-1.5h-2.6l-0.4,1.5h-2.4l2.6-8.1h2.9l2.7,8.1H155.3z M154.5,83.4l-0.3-1.3\n\t\t\tc-0.1-0.3-0.2-0.7-0.3-1.1c-0.1-0.5-0.2-0.8-0.2-1c0,0.2-0.1,0.5-0.2,0.9c-0.1,0.4-0.3,1.3-0.6,2.5H154.5z"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M166.2,86.7h-2.8l-2.9-5.7h0c0.1,0.9,0.1,1.6,0.1,2v3.6h-1.9v-8h2.8l2.9,5.6h0c-0.1-0.8-0.1-1.5-0.1-1.9v-3.6\n\t\t\th1.9V86.7z"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M173.2,84.2c0,0.5-0.1,0.9-0.4,1.3c-0.3,0.4-0.6,0.7-1.1,0.9c-0.5,0.2-1,0.3-1.7,0.3c-0.5,0-1,0-1.3-0.1\n\t\t\tc-0.4-0.1-0.7-0.2-1.1-0.4v-1.9c0.4,0.2,0.8,0.4,1.3,0.5c0.4,0.1,0.9,0.2,1.2,0.2c0.3,0,0.6-0.1,0.7-0.2c0.2-0.1,0.2-0.3,0.2-0.4\n\t\t\tc0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.5-0.3-1.1-0.5c-0.5-0.2-0.9-0.5-1.2-0.7c-0.3-0.2-0.5-0.5-0.6-0.8\n\t\t\tc-0.1-0.3-0.2-0.6-0.2-1c0-0.7,0.3-1.3,0.8-1.7c0.5-0.4,1.3-0.6,2.2-0.6c0.8,0,1.7,0.2,2.5,0.6l-0.7,1.7c-0.8-0.3-1.4-0.5-1.9-0.5\n\t\t\tc-0.3,0-0.5,0-0.6,0.1c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.2,0.1,0.3,0.2,0.4c0.2,0.1,0.6,0.4,1.3,0.7c0.7,0.3,1.2,0.6,1.4,1\n\t\t\tC173,83.3,173.2,83.7,173.2,84.2z"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M180.2,81.2c0,0.9-0.3,1.6-0.8,2.1c-0.5,0.5-1.3,0.7-2.3,0.7h-0.6v2.7h-2.2v-8h2.8c1,0,1.8,0.2,2.3,0.7\n\t\t\tC180,79.8,180.2,80.4,180.2,81.2z M176.6,82.3h0.4c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.4,0.3-0.8c0-0.5-0.3-0.8-0.9-0.8h-0.6\n\t\t\tV82.3z"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M189,82.7c0,1.4-0.3,2.4-1,3.1c-0.7,0.7-1.6,1.1-2.9,1.1c-1.3,0-2.2-0.4-2.9-1.1c-0.7-0.7-1-1.7-1-3.1\n\t\t\tc0-1.3,0.3-2.4,1-3.1c0.7-0.7,1.6-1.1,2.9-1.1c1.3,0,2.2,0.3,2.9,1S189,81.3,189,82.7z M183.5,82.7c0,1.6,0.5,2.3,1.6,2.3\n\t\t\tc0.5,0,1-0.2,1.2-0.6c0.3-0.4,0.4-1,0.4-1.8c0-0.8-0.1-1.4-0.4-1.8c-0.3-0.4-0.7-0.6-1.2-0.6C184,80.3,183.5,81.1,183.5,82.7z"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M192.6,83.8v2.9h-2.2v-8h2.6c2.2,0,3.3,0.8,3.3,2.4c0,0.9-0.5,1.6-1.4,2.2l2.3,3.5h-2.5l-1.7-2.9H192.6z\n\t\t\t M192.6,82.1h0.4c0.8,0,1.1-0.3,1.1-1c0-0.6-0.4-0.8-1.1-0.8h-0.4V82.1z"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: { d: "M201.6,86.7h-2.2v-6.3h-2v-1.8h6.1v1.8h-2V86.7z" }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M209.6,86.7l-0.4-1.5h-2.6l-0.4,1.5h-2.4l2.6-8.1h2.9l2.7,8.1H209.6z M208.8,83.4l-0.3-1.3\n\t\t\tc-0.1-0.3-0.2-0.7-0.3-1.1c-0.1-0.5-0.2-0.8-0.2-1c0,0.2-0.1,0.5-0.2,0.9c-0.1,0.4-0.3,1.3-0.6,2.5H208.8z"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: { d: "M216.4,86.7h-2.2v-6.3h-2v-1.8h6.1v1.8h-2V86.7z" }
          }),
          _vm._v(" "),
          _c("path", { attrs: { d: "M219.5,86.7v-8h2.2v8H219.5z" } }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M230.9,82.7c0,1.4-0.3,2.4-1,3.1c-0.7,0.7-1.6,1.1-2.9,1.1c-1.3,0-2.2-0.4-2.9-1.1c-0.7-0.7-1-1.7-1-3.1\n\t\t\tc0-1.3,0.3-2.4,1-3.1c0.7-0.7,1.6-1.1,2.9-1.1c1.3,0,2.2,0.3,2.9,1C230.6,80.3,230.9,81.3,230.9,82.7z M225.4,82.7\n\t\t\tc0,1.6,0.5,2.3,1.6,2.3c0.5,0,1-0.2,1.2-0.6c0.3-0.4,0.4-1,0.4-1.8c0-0.8-0.1-1.4-0.4-1.8c-0.3-0.4-0.7-0.6-1.2-0.6\n\t\t\tC226,80.3,225.4,81.1,225.4,82.7z"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M240,86.7h-2.8l-2.9-5.7h0c0.1,0.9,0.1,1.6,0.1,2v3.6h-1.9v-8h2.8l2.9,5.6h0c-0.1-0.8-0.1-1.5-0.1-1.9v-3.6\n\t\t\th1.9V86.7z"
            }
          })
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }