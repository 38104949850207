var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "footer",
    {
      staticClass:
        "flex flex-col md:flex-row flex-wrap items-center justify-center md:justify-between bg-gray-300 text-black p-2 border-black border-t",
      attrs: { role: "banner" }
    },
    [
      _vm._t("logos", [
        _c("img", {
          staticClass: "w-48 md:w-64",
          attrs: {
            title: "PBOT Technology services logo",
            alt: "PBOT Technology services logo",
            src: "/img/pbot-verticalapps-logo-dark.png"
          }
        })
      ]),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _vm._t("copyright", [
        _c("div", { staticClass: "flex flex-col md:flex-row items-center" }, [
          _c("img", {
            staticClass: "w-20 md:w-24 m-2",
            attrs: {
              src:
                "https://www.portland.gov/themes/custom/cloudy/images/city-seal.png"
            }
          }),
          _vm._v(" "),
          _c("p", { staticClass: "m-0" }, [
            _vm._v(
              "© City of Portland, Oregon " + _vm._s(new Date().getFullYear())
            )
          ])
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }